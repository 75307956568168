/* You can add global styles to this file, and also import other style files */
html {
  font-size: 0.7em !important;
  font-family: "Verdana", "Geneva", "sans-serif" !important;
}

body {
  margin: 0 !important;
  font-size: 1em !important;
  font-family: "Verdana", "Geneva", "sans-serif" !important;
  background: #edf1f7;

  .ui-widget {
    font-size: 1em !important;
    font-family: "Verdana", "Geneva", "sans-serif" !important;
    //box-shadow: 0 5px 22px -15px black;
  }
  
  .p-component {
    font-family: "Verdana", "Geneva", "sans-serif" !important;
  }

  p-tabview {
    .ui-widget {
      box-shadow: none;
    }
    .ui-tabview {
      &.ui-tabview-top .ui-tabview-nav {
        border-bottom: 1px solid #a6a6a6 !important;
        margin-bottom: 1px !important;
      }

      .ui-tabview-panels {
        border: none;
      }
    }
  }

  .p-panel-title{
    font-weight: bold;
    font-size: initial;
  }

  .ui-panel-title{
    font-weight: bold;
    font-size: initial;
  }

  .body-dashboard {
    background: #eaecff;
  }
  .ui-dataview .ui-dataview-header {
    background: #ffffff;
  }
  .ui-panel {
    .ui-panel-content {
      padding: 2em 3em;
    }
    .ui-panel-titlebar {
      background-color: #ffffff;
    }
  }
  .ui-dialog .ui-dialog-titlebar {
    background-color: #ffffff;
  }
  .ui-autocomplete .ui-autocomplete-input {
    width: 100%;
  }
  .ui-autocomplete.ui-widget.ui-autocomplete-dd {
    width: 100%;
  }
  .ui-autocomplete-dd input.ui-corner-all {
    width: 70%;
  }
  .ui-dialog .ui-dialog-content {
    padding: 0.571em 2em;
  }
  input,
  p-calendar {
    //border: 1px solid #a6a6a6 !important;

    &.ui-inputwrapper-filled {
      border: 0px solid #a6a6a6 !important;
    }
  }

  .ui-chips > ul.ui-inputtext .ui-chips-input-token input {
    border: 0 none !important;
  }

  .ui-fieldset {
    box-shadow: none !important;
  }

  .ui-accordion {
    .ui-accordion-content {
      padding: 1em;
    }

    .ui-accordion-header:not(.ui-state-disabled).ui-state-active a {
      background-color: #ffffff;
      color: #007ad9;

      .ui-accordion-toggle-icon {
        color: #007ad9;
      }
    }
  }
}

.ui-dialog .ui-dialog-content {
  font-size: 1em !important;
  font-family: "Verdana", "Geneva", "sans-serif" !important;
}

.ui-outputlabel {
  margin-top: 3px !important;
}

.ui-dropdown {
  height: 25px !important;
  display: block !important;
  min-width: auto !important;

  &.ui-widget {
    border: none;
    border-bottom: 1px solid #a6a6a6;
    &.ui-corner-all {
      border-radius: 0;
    }
  }
}

.ui-multiselect {
  width: 100%;
  &.ui-widget {
    border: none;
    border-bottom: 1px solid #a6a6a6;
    &.ui-corner-all {
      border-radius: 0;
    }
  }
}

p-autocomplete {
  width: 100%;
  input {
    border: none;
    border-bottom: 1px solid #a6a6a6;
    border-radius: 0;
  }
}

.ui-dropdown-label {
  padding-bottom: 1px !important;
  padding-top: 2px !important;
  font-size: 12px !important;
}

.chart-legend .legend-labels {
  white-space: inherit !important;
  //width: 400px !important;
}

.chart-legend .legend-label-text {
  white-space: inherit !important;
}

.tooltipChart {
  //font-family: "Open Sans", "Helvetica Neue", sans-serif !important;
  font-family: "Verdana", "Geneva", "sans-serif" !important;
}

.ui-dialog .ui-dialog-titlebar {
  font-size: 15px !important;
}

.ui-dialog .ui-dialog-content {
  font-size: 14px !important;
}
/*.chart-legend ul{
    width: 390px !important;
}
*/
@media only screen and (min-width: 300px) and (max-width: 600px) {
  .chart-legend ul {
    width: calc(100% - 55px) !important;
  }
}

@media (min-width: 600px) and (max-width: 640px) {
  .chart-legend ul {
    width: 511px !important;
  }
}

@media (min-width: 641px) and (max-width: 950px) {
  .chart-legend ul {
    width: calc(100% - 75px) !important; //300px !important;
  }
}

@media (min-width: 951px) {
  .chart-legend ul {
    width: calc(100% - 45px) !important; //300px !important;
  }
}

.wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  min-height: 100%;
  padding: 20px;
}

.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@-webkit-keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.center {
  display: block !important;
  margin-left: auto !important;
  margin-right: auto !important;
  padding-top: 10px !important;
  //opacity: 0.3 !important;
}

.ui-button .ui-button-text {
  color: none !important;
}

/* Inicio - Estilos para el required */
input.ng-valid[required],
input.ng-valid.required,
select.ng-valid[required],
select.ng-valid.required,
textarea.ng-valid[required],
textarea.ng-valid.required,
.custom-file.ng-valid,
simple-tiny.ng-valid
  > .mce-tinymce.mce-container.mce-panel
  > .mce-container-body.mce-stack-layout.custom-file.ng-valid {
  border: 1px solid #a6a6a6 !important;
  border-left: 3px solid #42a948 !important;
}

input.ng-invalid:not(form),
select.ng-invalid:not(form),
textarea.ng-invalid:not(form),
.custom-file.ng-invalid,
simple-tiny.ng-invalid > .mce-tinymce.mce-container.mce-panel > .mce-container-body.mce-stack-layout {
  border: 1px solid #a6a6a6 !important;
  border-left: 3px solid #a94442 !important;
}

p-dropdown.ng-valid[required] .ui-dropdown,
p-dropdown.ng-valid[required] > .ui-dropdown,
p-multiselect.ng-valid[required] > .ui-multiselect,
p-autocomplete.ng-valid[required] > .ui-autocomplete > .ui-inputtext {
  border: none;
  border-left: 3px solid #42a948 !important;
  border-bottom: 1px solid #a6a6a6 !important;
}

p-autocomplete.ng-valid[required] > .ui-autocomplete .ui-autocomplete-input,
p-calendar.ng-valid[required] .ui-inputtext,
p-chips.ng-valid[required] .ui-inputtext {
  border-left: 3px solid #42a948 !important;
}

p-dropdown.ng-invalid[required] .ui-dropdown,
p-dropdown.ng-dirty.ng-invalid > .ui-dropdown,
p-multiselect.ng-dirty.ng-invalid > .ui-multiselect,
p-autocomplete.ng-dirty.ng-invalid > .ui-autocomplete > .ui-inputtext {
  border: none !important;
  border-left: 3px solid #a94442 !important;
  border-bottom: 1px solid #a6a6a6 !important;
}

p-autocomplete.ng-invalid[required] > .ui-autocomplete .ui-autocomplete-input,
p-calendar.ng-invalid[required] .ui-inputtext,
p-chips.ng-invalid[required] .ui-inputtext {
  border-left: 3px solid #a94442 !important;
}

.ng-invalid:not(form)  {
  border-left: 3px solid #a94442; /* red */
}

.ng-valid[required], .ng-valid.required  {
 border-left: 3px solid #42A948; /* green */
}

p-dropdown.ng-dirty.ng-touched > .ui-dropdown{
  border-left: 3px solid #42A948 !important; /* green */
 }
/* Fin - Estilos para el required */

/* Tamaño del calendar */
body {
  .ui-datepicker table {
    font-size: 9px;
  }
  .ui-dialog .ui-dialog-content {
    font-size: 1em !important;
  }
}

.ui-inputgroup input {
  color: #333333;
  background: #ffffff;
  padding: 0.429em;
  border: 1px solid #a6a6a6;
  transition: border-color 0.2s;
  -webkit-appearance: none;
}

.swal2-container {
  z-index: 15000 !important;
}

.ui-toast.ui-widget.ui-toast-top-right {
  z-index: 16000 !important;
}

.ui-dialog-resizable .ui-dialog-content {
  overflow: inherit;
}

.ui-autocomplete-dd input.ui-corner-all {
  width: 193px;
}

.overlay {
  position: fixed !important;
}

/* Estilos Disabled */
input {
  border: 1px solid #a6a6a6;
  border-radius: 3px;
  padding: 0.429em;
  &:disabled {
    background-color: #e9ecef;
    opacity: 1 !important;
    border: 1px solid #ced4da;
  }
}

/* Estilos Btns */
/* Colores */
$primary: #007bff;
$secondary: #6c757d;
$info: #17a2b8;
$light: #ffffff;
$success: #28a745;
$warning: #ffc107;
$danger: #dc3545;
$dark: #343a40;

.btn-bs-primary {
  border-color: $primary !important;
  background-color: transparent !important;
  border: 1px solid $primary !important;
  padding: 0.375rem 0.75rem;
  color: $primary !important;
  &:enabled {
    &:hover {
      background-color: $primary !important;
      color: $light !important;
    }
  }
}

.btn-bs-secondary {
  border-color: #6c757d !important;
  background-color: transparent !important;
  border: 1px solid #6c757d !important;
  padding: 0.375rem 0.75rem;
  color: #6c757d !important;
  &:enabled {
    &:hover {
      background-color: #6c757d !important;
      color: $light !important;
    }
  }
}

.btn-bs-info {
  border-color: $info !important;
  background-color: transparent !important;
  border: 1px solid $info !important;
  padding: 0.375rem 0.75rem !important;
  color: $info !important;
  &:enabled {
    &:hover {
      background-color: $info !important;
      color: $light !important;
    }
  }
}

.btn-bs-dark {
  border-color: $dark !important;
  background-color: transparent !important;
  border: 1px solid $dark !important;
  padding: 0.375rem 0.75rem;
  color: $dark !important;
  &:enabled {
    &:hover {
      background-color: $dark !important;
      color: $light !important;
    }
  }
}

.btn-bs-danger {
  border-color: $danger !important;
  background-color: transparent !important;
  border: 1px solid $danger !important;
  padding: 0.375rem 0.75rem;
  color: $danger !important;
  &:enabled {
    &:hover {
      background-color: $danger !important;
      color: $light !important;
    }
  }
}

.btn-bs-warning {
  border-color: $warning !important;
  background-color: transparent !important;
  border: 1px solid $warning !important;
  padding: 0.375rem 0.75rem;
  color: $warning !important;
  &:enabled {
    &:hover {
      background-color: $warning !important;
      color: $light !important;
    }
  }
}

.btn-bs-success {
  border-color: $success !important;
  background-color: transparent !important;
  border: 1px solid $success !important;
  padding: 0.375rem 0.75rem;
  color: $success !important;
  &:enabled {
    &:hover {
      background-color: $success !important;
      color: $light !important;
    }
  }
}


/* Estilos del componente tabla */
::ng-deep .ui-table-thead input {
  width: 116px !important;
}

@media only screen and (min-width: 300px) and (max-width: 640px) {
  /*::ng-deep .ui-table table {
     width: auto !important;
  }*/

  .positionCurrency,
  .positionSemaforo {
    text-align: initial !important;
  }

  ::ng-deep .ui-table .ui-table-tbody > tr {
    border: 1px solid #c8c8c8 !important;
  }

  //@-moz-document url-prefix(){
  @media screen and (min--moz-device-pixel-ratio: 0) {
  }
  ::ng-deep .ui-table.ui-table-responsive .ui-table-tbody > tr > td {
    /* Firefox >= 0.9 */
    width: calc(100%) !important;
  }
  //}

  ::ng-deep .ui-table-caption {
    display: block;
  }
}

@media only screen and (min-width: 641px) {
  ::ng-deep .ui-table-caption {
    display: none;
  }
}

::ng-deep .ui-table .ui-table-tfoot > tr > td {
  background-color: #007ad9 !important;
  color: white !important;
}

::ng-deep .ui-table .ui-table-tbody > tr > td.ui-editing-cell {
  padding-left: 9px;
}

::ng-deep .ui-widget-overlay.ui-dialog-mask {
  z-index: 99 !important;
}

/** cards */
.stats {
  background-color: #ffffff;
  min-height: 80px;
  position: relative;
  margin-top: 29px;
  border-top: 2px solid rgba(15, 80, 195, 0.86);
  border-bottom: solid 1px #d6d7d9;
  border-right: solid 1px #d6d7d9;
  border-left: solid 1px #d6d7d9;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;

  .stats-icon {
    display: inline-block;
    position: relative;
    top: -7px;
    left: 30px;
    background: #ffffff;
    padding: 0 5px;
    height: 7px;
  }

  .stats-title {
    line-height: 21px;
    font-size: 17px;
  }

  .stats-body {
    text-align: left;
    padding: 1.5em 1.5em 0 1.5em;
  }
}

.table-cell {
  white-space: normal; /* Permite que el texto se ajuste automáticamente en varias líneas */
  overflow: hidden; /* Oculta cualquier contenido que desborde del contenedor */
  text-overflow:  visible; /* Agrega puntos suspensivos (...) cuando el texto desborda */
}

.p-datatable-scrollable-header-box{
  padding-right: 0 !important;
}

.p-datepicker:not(.p-datepicker-inline){
  z-index: 11024 !important;
}

.p-datatable .p-datatable-tbody > tr.p-highlight {
  background: #E3F2FD;
  color: #495057;
}
.p-dropdown-panel{
  z-index: 11000 !important;
}